/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.PDPRelatedPosts {
    &-Wrapper {
        padding: 0px 0px;
        margin-block-start: 72px;
        flex-direction: column;

        @include desktop {
            padding: 0px 60px;
            margin-block-start: 120px;
            flex-direction: row;
        }
    }

    &-ImageWrapper {
        @include desktop {
            margin-block-start: 65px;
            margin-inline: auto 0;
        }
    }

    &-Heading {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 126%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-block-end: 8px;
        margin-block-start: 0px;

        @include desktop {
            margin-block-start: 16px;
        }
    }

    &-Container {
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
            gap: 80px;
        }

        @include desktop {
            flex-direction: row;
            gap: 80px;

            img {
                @include desktop {
                    padding-inline-end: 0px;
                    max-height: 532px;
                    max-width: 788px;
                }
            }
        }
    }

    &-TextWrapper {
        padding-inline-end: 0px;

        p {
            margin-block-end: 0px;

            @include desktop {
                padding-inline-end: 84px;
                font-size: 1.125rem;
            }
        }
    }

    &-Title {
        font-family: $font-Prospectus-Pro-Cyrillic-Italic;
        font-size: 2.625rem;
        line-height: 100%;

        @include desktop {
            font-size: 3.25rem;
        }
    }

    &-Content {
        padding-block-start: 24px;
        padding-block-end: 24px;
        font-size: var(--paragraph-font-size);
        line-height: 142%;
        width: 100%;

        @include desktop {
            max-width: 500px;

            p {
                line-height: 142%;
            }
        }
    }

    &-ReadMoreButtonWrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: var(--primary-base-color);
        border: 1px solid var(--primary-base-color);

        @include desktop {
            width: initial;
            height: initial;
        }

        &:hover {
            background-color: var(--color-black);
            border-color: var(---color-white);
        }
    }

    &-ReadMoreButton {
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 126%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--color-white);

        &:active, &:focus, &:visited, :hover {
            color: var(--color-white);
        }
    }

    &-ExploreBlogButtonWrapper {
        justify-content: center;
        align-items: center;
        border: 1.4px solid var(--primary-base-color);

        @include desktop {
            height: initial;
        }

        a {
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 126%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            text-decoration: none;
            color: var(--primary-base-color);
            background-color: transparent;
            border: none;

            &:hover {
                background-color: var(--primary-base-color);
                color: var(--color-white);
            }
        }
    }

    &-ButtonWrapper {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding-block-end: 32px;

        @include desktop {
            padding-block-end: 0px;
        }
    }
}
