/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.PostPage {
    &_isLoading {
        min-height: 100vh;
    }

    a {
        font-family: $font-Klavika;
        font-size: 1rem;
        font-weight: 400;
        line-height: 126%;
    }

    .Post-Content {
        a {
            text-decoration: underline;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        margin-block: 52px 24px;
    }

    hr {
        height: 1px;
        background: $black;
        margin-block: 0;
    }

    li {
        margin-block-end: 8px;

        &::before {
            position: static;
            padding-inline-start: 8px;
            padding-inline-end: 12px;
            box-sizing: content-box;
        }

        &:last-child {
            margin-block-end: 0;
        }
    }

    ul {
        margin-block: 24px 52px;
        margin-inline-start: 30px;

        li {
            &::before {
                min-width: 16px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    ol {
        margin-block: 24px;
        margin-inline-start: 30px;

        li {
            &::before {
                min-width: 20px;
                display: inline-block;
            }
        }
    }

    blockquote {
        border: none;
        padding: 24px 0;
        margin: 0;

        :first-child {
            font-weight: 400;
            font-size: 2rem;
            line-height: 2rem;
            margin-block: 0 16px;
            font-family: $font-Prospectus-Pro-Cyrillic-Italic;

            @include desktop {
                font-size: 2.625rem;
                line-height: 2.625rem;
            }
        }

        :last-child {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.25rem;
            text-align: end;
            margin-block: 0;
        }
    }

    .pagebuilder-video-container {
        iframe {
            width: 100%;
        }
    }

    .pagebuilder-mobile-hidden {
        display: none;

        @include desktop {
            display: block;
        }
    }

    .pagebuilder-mobile-only {
        @include desktop {
            display: none;
        }
    }

    .Post {
        &-FeaturedImage {
            aspect-ratio: 328 / 222;

            @include desktop {
                aspect-ratio: 872 / 589;
            }

            .Image {
                padding-block-end: 0;
                display: block;

                &-Image {
                    object-fit: fill;
                }
            }
        }

        &-Date {
            opacity: 0.5;
            font-size: 0.875rem;
            line-height: 1.125rem;
            margin-block: 16px 8px;
            color: $black;
        }

        &-Title {
            margin-block: 0 52px;
        }

        &-Content, &-Header {
            @include desktop {
                max-width: 872px;
                margin-inline: auto;
            }

            img {
                min-width: 100%;
                max-width: 100%;
                height: initial !important;

                @include desktop {
                    min-width: unset;
                    max-width: unset;
                    height: unset;
                }

                ~ img {
                    @include desktop {
                        margin-inline-start: 23px;
                    }
                }
            }
        }

        &-Footer {
            margin-block-start: 72px;

            @include desktop {
                margin-block-start: 120px;
            }
        }

        &-RelatedProducts {
            a {
                font-family: var(--current-font-family);
            }

            ul {
                li {
                    &::before {
                        display: none;
                    }
                }
            }

            .Slider-Wrapper {
                background: transparent;
            }

            .ProductList {
                &_isPaginationHidden {
                    @include after-mobile {
                        padding-block-end: 0;
                    }

                    .ProductListPage {
                        @include after-mobile {
                            padding-block-end: 0;
                            display: flex;
                            justify-content: center;
                        }

                        .ProductCard {
                            @include after-mobile {
                                width: 25%;
                            }
                        }
                    }
                }

                &-Message {
                    padding-block: 0;
                    margin-block-start: 32px;
                    display: none;

                    span {
                        display: none;
                    }

                    &-StatusBar {
                        margin-block-start: 0;
                        width: calc(100% - 62px);
                        margin-inline: 0 auto;
                    }
                }
            }

            .ProductCard {
                margin-block-end: 0;
                animation: appear-nice 1.25s cubic-bezier(.19,1,.22,1) forwards;

                &-Name {
                    text-decoration: none;

                    @include after-mobile {
                        margin-block-start: 10px;
                        height: unset;
                    }
                }

                &-IconsWrapper {
                    @include after-mobile {
                        display: none;
                    }
                }

                &-OutOfStock {
                    @include after-mobile {
                        display: none;
                    }
                }

                &-OutOfStockWrapper {
                    @include after-mobile {
                        display: flex;
                        opacity: 1;
                    }
                }

                &-AddToCardAndIconWrapper {
                    @include after-mobile {
                        display: none;
                    }
                }

                &-FigureReview {
                    &_isHovered {
                        @include after-mobile {
                            display: flex;
                        }

                        .ProductCard {
                            &-Description {
                                @include after-mobile {
                                    display: block;
                                }
                            }

                            &-ImagesWrapper {
                                @include after-mobile {
                                    padding-inline: 16px;
                                    padding-block-end: 12px;
                                }

                                .Image {
                                    padding-block-end: 0;

                                    @include mobile {
                                        padding-block-end: 120%;
                                    }
                                }
                            }

                            &-AddToCart {
                                @include after-mobile {
                                    width: unset;
                                }
                            }

                            &-PlusIconWrapper {
                                @include after-mobile {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                &:hover {
                    &-FigureReview {
                        &_isHovered {
                            opacity: 1;
                        }
                    }

                    .ProductCard {
                        &-Description {
                            @include after-mobile {
                                opacity: 1;
                            }
                        }
                    }
                }

                &-AddToCartWrapper {
                    @include after-mobile {
                        display: flex;
                        opacity: 1;
                    }
                }
            }

            .Pagination {
                position: absolute;
                inset-block-end: -27px;
                inset-inline-end: 0;
                gap: 16px;
                display: flex;

                &-ListItem {
                    width: fit-content;
                    min-width: 0;

                    &_isDisabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }

                    &::before {
                        display: none;
                    }
                }

                .PaginationLink_isArrow {
                    border: none;
                    padding: 0;
                    margin-inline: 0;
                    display: flex;
                    justify-content: center;
                    width: fit-content;
                }
            }

            .BlogPostRelatedProducts {
                h2 {
                    display: none;
                }

                &-Page {
                    padding-inline: 0;
                }

                .Slider {
                    .ProductList {
                        &-Product {
                            @media (min-width: 380px) and (max-width: 960px) {
                                width: 304px;
                            }
                            width: 80vw;
                            padding-inline-end: 16px;

                            .ProductCard:hover {
                                @include before-desktop {
                                    .ProductLabels {
                                        visibility: hidden !important; /* stylelint-disable declaration-no-important */
                                    }
                                }
                            }
                        }
                    }
                }

                .ProgressBar {
                    &-Actions {
                        margin-block-start: 28px;
                    }
                }

                [dir="ltr"] & {
                    .ProductPrice-PriceValue,
                    .ProductPrice-HighPrice {
                        @include tablet {
                            font-size: 1.125rem;
                        }
                    }
                }
            }
        }
    }
}
