/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --checkbox-width: 1.5rem;
    --checkbox-height: 1.5rem;
}

.Field {
    input {
        font-size: 1rem;
        line-height: 1.25rem;
        padding-block: 1.625rem 0.375rem;
        height: auto;

        &[type='checkbox'] {
            &[disabled] + .CheckBoxIconControl {
                path {
                    opacity: 0.3;
                }
            }

            &:checked + .CheckBoxIconControl {
                border-color: transparent;
            }
        }
    }

    &-SubLabel {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1rem;
        opacity: 0.5;
        padding-block-start: 4px;
    }

    &-ErrorMessage {
        line-height: 1.125rem;
        padding-block-start: 12px;
        padding-inline-start: 1px;
    }

    &-ErrorMessages {
        padding-block-start: unset;
        margin-block-start: -8px;
        padding-block-end: 4px;
    }

    &-PlaceHolder {
        inset-inline-start: 16px;
        inset-block-start: 1.063rem;
        line-height: 1.25rem;
    }

    &-PasswordIconWrapper {
        inset-block-start: 0.75rem;
        height: 1.5rem;
        width: 1.5rem;
    }

    &_hasError {
        input#date_of_birth {
            color: var(--primary-error-color);
        }

        :not(input:focus) input:focus {
            border-block-end: 1px solid var(--primary-error-color);
        }
    }

    input:focus {
        border-block-end: 1px solid $black;
    }

    input:focus ~ &-PlaceHolder,
    input:not(:focus):not(:placeholder-shown) ~ &-PlaceHolder {
        font-size: 0.875rem;
        inset-block-start: 0.375rem;
    }

    &_type_date {
        .Field-PlaceHolder {
            font-size: 0.875rem;
            inset-block-start: 0.375rem;
        }

        &.Field_isValid {
            input {
                border: none;
                border-block-end: 1px solid rgba(var(--rgb-black-color), 0.2);

                &:focus {
                    border-block-end: 1px solid $black;
                }

                &:placeholder-shown {
                    border: none;
                }
            }
        }
    }

    &-CheckboxLabel, &-RadioLabel {
        &:not([class*='_isDisabled']):hover {
            @include desktop {
                color: unset;
            }
        }
    }

    &-CheckboxLabel {
        grid-gap: 12px;
        gap: 12px;
        align-items: flex-start;

        .CheckBoxIconControl {
            border-width: 1px;
        }

        &-LabelContent {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.25rem;
        }
    }

    &_type_text.Field_isValid input[name="postcode"] {
        border: none;
        border-bottom: 1px solid var(--color-dark-gray);
    }

    .LoadingIcon {
        &,
        &-Stroke,
        &-LeftStroke,
        &-RightStroke {
            width: 1rem;
            height: 1rem;
        }

        &-LeftStroke {
            clip: rect(0 0.5rem 1rem 0);

            &::before {
                width: 1rem;
                height: 1rem;
                clip: rect(0 1rem 1rem 0.5rem);
            }
        }

        &-RightStroke {
            clip: rect(0 1rem 1rem 0.5rem);

            &::before {
                width: 1rem;
                height: 1rem;
                clip: rect(0 0.5rem 1rem 0);
            }
        }
    }
}

.Field_type_date.Field_hasErorr {
    input {
        border-block-end: 1px solid var(--primary-error-color);
    }
}

.CheckBoxIconControl { // it's here because it should have low priority
    width: var(--checkbox-width);
    min-width: var(--checkbox-width);
    height: var(--checkbox-height);
    border-color: rgba($black, 0.35);

    &:hover {
        border-color: $black;
    }
}
