/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

:root {
    --paypal-review-page-table-head-background: var(--secondary-base-color);
}

.PayPalReviewPage {
    font-size: 14px;

    @include mobile {
        margin-block-end: 180px;
    }

    &-Wrapper {
        display: flex;
        flex-flow: column wrap;
        gap: 40px;
        min-height: calc(50vh + var(--header-total-height));

        @include mobile {
            gap: 20px;
            padding-block-start: 34px;
            min-height: calc(80vh + var(--header-total-height));
        }
    }

    &-ShippingAddress {
        address {
            font-style: normal;
        }
    }

    &-BillingAddressInfo {
        display: flex;
        flex-flow: column wrap;
        gap: 2px;
    }

    &-Details {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 40px;
        width: 100%;

        @include mobile {
            display: flex;
            flex-flow: column wrap;
            gap: 10px;
        }
    }

    &-ItemsTitle {
        display: flex;
        font-size: 24px;
        padding: 0 0 10px;

        @include mobile {
            flex-flow: column wrap;
        }
    }

    &-ItemsEdit {
        font-size: 14px;
        margin: 12px 0 0 30px;

        @include mobile {
            margin: 12px 0 0;
        }
    }

    &-TableHead {
        display: grid;
        grid-template-columns: 3fr 2fr 1fr;
        background-color: var(--paypal-review-page-table-head-background);
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
        padding: 14px 16px;
        margin-block-end: 0;
        border-radius: var(--input-border-radius);

        @include mobile {
            display: none;
        }

        span {
            text-align: start;

            &:last-of-type {
                text-align: end;
            }
        }
    }

    &-PlaceOrderButtonWrapper {
        display: flex;
        justify-content: flex-end;

        @include desktop {
            margin: 20px 0;
        }
    }

    &-PlaceOrderButton {
        min-width: 180px;
    }
}
