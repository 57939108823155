/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.RulesProgressBar {
    &-Wrapper {
        display: flex;
        justify-content: flex-end;
    }

    &-Content {
        flex-basis: 80%;
        display: flex;
        flex-direction: column;
    }

    &-Actions {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
    }

    &-Bar {
        background-color: rgba(var(--black-rgb-color), 0.16);
        height: 1px;
        width: 100%;
    }

    &-CurrentProgress {
        background-color: var(--primary-base-color);
        height: 1px;
        transition: inset 0.3s ease;
        max-width: 100%;
    }

    &-Header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > p {
            margin-block-end: 0.5rem;
        }
    }

    &-PriceLeft {
        color: var(--color-black);
        opacity: 0.5;
    }

    &-Condition {
        align-self: flex-end;
        font-weight: 900;
        margin-block-start: 0.375rem;
        margin-block-end: 0;
        font-size: 1.125rem;
        line-height: 1.625rem;
    }
}
