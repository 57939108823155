/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Copyright © Magefan (support@magefan.com). All rights reserved.
 * Please visit Magefan.com for license details (https://magefan.com/end-user-license-agreement).
 *
 * Glory to Ukraine! Glory to the heroes!
 *
 * Original source: vendor/magefan/module-blog/view/frontend/web/css
 * Most Parts of these styles are copied from original extension Magefan Blog
 */

@keyframes verticalMovement {
    from {
        inset-block-start: 70px;
    }

    to {
        inset-block-start: 0;
    }
}

@keyframes scale {
    from {
        transform: scale(1.05);
    }

    to {
        transform: scale(1);
    }
}

.PostItem {
    margin-block: 0;
    animation-timing-function: ease-in;
    animation-name: verticalMovement;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    list-style-type: none;

    @include desktop {
        &:nth-child(5n+1), &:nth-child(5n+2) {
            width: calc(50% - 16px);

            @include tablet {
                width: initial;
            }
        }

        &:nth-child(5n+3), &:nth-child(5n+4), &:nth-child(5n) {
            width: calc(33.33% - 22px);

            @include tablet {
                width: initial;
            }

            .PostItem-Image {
                aspect-ratio: 1;

                @include tablet {
                    aspect-ratio: 328 / 222;
                }
            }
        }
    }

    * {
        color: inherit;
    }

    &::before {
        display: none;
    }

    &:last-child {
        margin-block-end: 0;
    }

    &-Link {
        color: $black;
        text-decoration: none;

        &:hover {
            color: $default-primary-base-color;
        }
    }

    &-Title {
        margin-block: 16px;
        color: inherit;

        @include tablet {
            font-size: 2.25rem;
            line-height: 2.25rem;
        }
    }

    &-Content {
        font-weight: 400;

        @include desktop {
            font-size: 1rem;
            line-height: 1.25rem;
        }

        @include tablet {
            font-size: 1rem;
            line-height: 1.25rem;
        }

        * {
            color: inherit;
        }

        p {
            @include tablet {
                font-size: 1rem;
                line-height: 1.25rem;
            }

            &:last-child {
                margin-block-end: 0;
            }
        }
    }

    &::marker {
        content: '';
    }

    &-Image {
        aspect-ratio: 328 / 222;

        .Image {
            padding-block-end: 0;
            display: block;
            animation-timing-function: ease-in;
            animation-name: scale;
            animation-duration: 300ms;
            animation-fill-mode: forwards;

            &-Image {
                object-fit: cover;
                transition: all ease-out 0.3s;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

