/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.ProductPage {
    .ProductPage-Wrapper {
        @include after-mobile {
            grid-template-areas: ". actions";
        }
    }
}

.AmastyBanner {
    &-TextBanner {
        background: $blue-banner-color;
        padding: 12px;

        h4 {
            text-transform: uppercase;
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 1.125rem;
            margin-block-end: 6px;
            letter-spacing: 0.08em;
        }

        p {
            margin-block: 0;
        }
    }

    &-TopBanner {
        margin-block: 20px;

        @include after-mobile {
            grid-area: top-banner;
            margin-block-start: 0;
        }

        &_isEmpty {
            margin-block-end: 0;
        }

        img {
            width: 100%;
        }

        &[href="#"] {
            margin-block: 0px;
            font-size: 0px;

            img {
                font-size: 0px;
            }
        }
    }

    &-AfterBanner {
        display: block;
        margin-block-start: 20px;

        &:empty {
            display: none;
        }
    }

    &-Label {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: -20px;
        z-index: 1;

        @include after-mobile {
            inset-inline-start: 20px;
        }

        img {
            height: auto;
            max-width: 100%;
            width: auto;
        }
    }
}
