/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.PaypalOrderSummary {
    border: none;
    padding: logical 24px 16px 8px;
    margin-block-end: 16px;
    background-color: var(--paypal-review-page-table-head-background);

    @include mobile {
        padding-block-end: 24px;
    }
}
