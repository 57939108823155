/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MenuOverlay {
    &-Container {
        @include large-tablet {
            padding-block-start: 87px;
        }
    }

    &-Close {
        inset-inline-end: 0;
        inset-block-start: 50%;
        transform: translateY(-50%);

        @include large-tablet {
            display: none;
        }
    }
}
