/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --middle-line-top-element-background: #fff;
    --middle-line-color: var(--primary-base-color);
}

.image-with-text-customize {
    display: flex;
    flex-direction: column;
    margin: 40px 15px;

    @include desktop {
        flex-direction: row;
        max-width: 1140px;
        margin: 0 auto;
        height: 400px;
        justify-content: space-between;
    }

    .image-with-text-mobile {
        height: 300px !important;
        margin: 10px;

        @include desktop {
            height: 100%;
            width: calc(100% - 20px);
        }

        img {
            object-fit: cover;
            width: 100% !important;
            height: 100% !important;
        }
    }

    .image-with-text-mobile,
    .image-with-text-middle-no-image {
        display: block;

        @include desktop {
            display: none;
        }
    }

    .image-with-text-desktop,
    .image-with-text-wrapper,
    .pagebuilder-video-container {
        display: flex;
        align-content: center;
        padding: 10px;
        width: 100%;

        @include desktop {
            padding: 60px 0;
            width: calc(45% - 16px);
        }
    }

    .image-with-text-desktop {
        display: none;

        @include desktop {
            align-items: center;
            display: flex;
            justify-content: center;
        }

        img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
        }
    }

    .pagebuilder-video-container {
        order: 1;

        @include desktop {
            order: unset;
        }

        iframe {
            width: 100%;
            height: 300px;

            @include desktop {
                height: 100%;
            }
        }
    }

    .image-with-text-wrapper {
        order: 2;
        flex-direction: column;
        margin-top: 15px;

        @include desktop {
            margin: 0;
            order: unset;
            flex-direction: row;
        }

        .mobile {
            display: flex;
            width: 100%;
            justify-content: center;
            height: 26px;
            margin-bottom: 10px;

            @include desktop {
                display: none;
            }
        }
    }

    .image-with-text-text-wrapper {
        @include desktop {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            text-align: justify;
            font-size: 18px;
        }
    }

    .image-with-text-text-middle {
        display: none;

        @include desktop {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
        }

        .cms-year {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: unset;
        }
    }

    .header-link-title {
        text-align: center;
        font-size: 25px;
        margin: 0 0 10px;

        @include desktop {
            text-align: left;
            margin-bottom: 15px;
        }
    }

    .content,
    .content > * {
        text-align: justify;
        font-size: 16px !important;
        line-height: 1.5rem;

        @include desktop {
            font-size: 18px !important;
        }
    }


    .middle-line {
        display: none;

        @include desktop {
            display: block;
            top: 20px;
            height: calc(35.5% + 5px);
            width: 2px;
            background: var(--middle-line-color);
            position: absolute;
            left: 50%;

            &::before {
                background-color: var(--middle-line-top-element-background);
                content: "";
                position: absolute;
                width: 20px;
                height: 20px;
                border: 2px solid var(--middle-line-color);
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-transform-origin: bottom left;
                transform-origin: bottom left;
                top: -21px;
                left: 1px;
            }

            &::after {
                content: "";
                position: absolute;
                top: 100%;
                left: -2px;
                width: 6px;
                height: 6px;
                background: var(--middle-line-color);
                -webkit-border-radius: 50%;
                border-radius: 50%;
            }

            &.last {
                bottom: 9px;
                height: 41.5%;
                width: 2px;
                background: var(--middle-line-color);
                opacity: 1;
                top: inherit;

                &::before {
                    display: none;
                }
            }
        }
    }
}
