/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.StyledButton {
    &-Button {
        font-weight: 600;
        -webkit-transition: background .3s ease-in-out, border .3s ease-in-out;
        transition: background .3s ease-in-out, border .3s ease-in-out;
        will-change: background;
        overflow: hidden;

        a {
            text-decoration: none;
        }
    }
}

.click {
    border: none;
    border-radius: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 0;
}

.click.feedback {animation: feedback .6s ease-out;}

@keyframes feedback {
    0% {
        background: rgba(255,255,255,0.2);
        height: 0px;
        width: 0px;
    }

    100% {
        background: rgba(255,255,255,0);
        height: 400px;
        width: 400px;
    }
}
