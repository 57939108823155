/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --light-green-fill-color: #32CB64;
    --yankees-blue-fill-color: #21293f;
    --light-grey-fill-color: #e5e5e5;
}

.MkIcon {
    width: 46px;
    height: 13px;

    &-Path1 {
        fill: var(--light-green-fill-color);
    }

    &-Path2 {
        fill: var(--light-grey-fill-color);
    }
    
    &-Path3 {
        fill: var(--yankees-blue-fill-color);
    }
}
