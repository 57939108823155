/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.GDPR {
    &FooterLink {
        margin-block-start: 12px;

        &-Text {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.25rem;
            color: black;
            cursor: pointer;

            &:hover {
                color: var(--link-hover);
                text-decoration: underline;
            }
        }
    }
}
