/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.InstagramSection {
    margin-block: 120px;
    padding-inline: var(--content-wrapper-padding-inline);

    @include mobile {
        margin-block: 72px;
    }

    &-Header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-block-end: 32px;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
        }
    }

    &-Title h3,
    &-Link a {
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 126%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-block: 0;
    }

    &-Title h3 {
        color: $black;
    }

    &-Link a.Link {
        position: relative;
        display: block;
        color: $default-primary-base-color;

        &::before {
            content: "";
            position: absolute;
            inset-block-end: 0;
            inset-inline-end: 0;
            width: 0;
            height: 2px;
            background-color: var(--color-black);
            transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
        }

        &:hover {
            border: none;
            padding-block-end: 0;
            color: var(--color-black);

            &::before {
                inset-inline-start: 0;
                inset-inline-end: auto;
                width: 100%;
            }
        }
    }

    &-Images {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 1px;

        .Image-Wrapper {
            &:first-of-type {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 3;
            }

            .Image {
                display: block;
            }

            img {
                transition: all ease-out 0.3s;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }

    &-Slider {
        .Slider-Wrapper {
            gap: 16px;
        }

        .Image,
        .Image-Wrapper {
            display: block;
            min-height: 272px;
            min-width: 272px;
            width: calc(100vw - 56px);
            height: calc(100vw - 56px);

            @include tablet {
                width: calc(73vw + 6px);
                height: calc(73vw + 6px);
            }

            @include medium-tablet {
                width: 304px;
                height: auto;
            }
        }
    }

    &-Controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-block-start: 32px;
        padding-inline: 16px;
    }

    &-Arrows {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
    }

    &-SliderWrapper {
        @include mobile {
            margin-inline-end: calc(-1 * var(--content-wrapper-padding-inline));
        }
    }

    .ProgressBar-Actions {
        margin-block-start: 24px;
    }
}

.HomePage {
    .CmsPage {
        .InstagramSection {
            @include desktop {
                padding-inline: 60px;
            }
        }
    }
}
