/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.HeroSliderText {
    position: absolute;
    inset-inline-end: 60px;
    inset-block-end: 120px;
    width: 30%;

    @include large-widescreen {
        inset-inline-end: calc((100vw - 2040px) / 2 + 60px);
    }

    @include mobile {
        width: calc(100% - 32px);
        inset-inline-end: 16px;
        inset-block-end: 100px;
    }

    &-Title, &-Description {
        color: $white;
    }

    &-Title {
        margin-block-end: 24px;

        h1 {
            color: $white;
            white-space: nowrap;
            transition-property: inset-block-end;
            transition-duration: 780ms;
            transition-timing-function: ease-in-out;
        }

        &_isVideoEnded {
            h1 {
                inset-block-end: 0px;
            }
        }
    }

    &-Description {
        font-size: 1.125rem;
        opacity: 1;
        transition: opacity 500ms ease-in-out;
        z-index: 1;

        @include mobile {
            font-size: 16px;
        }
    }

    &-TitleLine {
        overflow-y: hidden;
        z-index: 1;

        h1 {
            inset-block-end: -120px;
        }

        &_isVideoEnded {
            z-index: 1;

            h1 {
                inset-block-end: 0px;
            }
        }
    }
}
