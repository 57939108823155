/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

$videoRatio: calc(16 / 9);
$videoWidthPercent: .597;
$desktopWidthWithNoPadding: calc(100vw - 120px);

.PageHeader {
    @include desktop {
        box-sizing: content-box;
        max-width: var(--content-wrapper-width);
        margin: 0 auto;
    }

    &_hasVideo {
        /*Setting height in big devices to overcome video height changing when video is loading*/
        @include narrow-desktop-plus {
            height: calc(((100vw - 64px) * #{$videoWidthPercent}) / #{$videoRatio});
        }

        @include desktop {
            height: calc((#{$desktopWidthWithNoPadding} * #{$videoWidthPercent}) / #{$videoRatio});
        }

        @include widescreen {
            height: calc((min(#{$desktopWidthWithNoPadding}, var(--content-wrapper-width)) * #{$videoWidthPercent}) / #{$videoRatio});
        }
    }

    &_hasImageBlock {
        @include narrow-desktop-plus {
            display: grid;
            grid-template-columns: 40.3% 59.7%;
            grid-template-rows: 1fr;
            grid-template-areas: ". image";
        }
    }

    &-HeadTextContainer {
        background-color: #{$default-primary-base-color};
        padding: 32px 16px;
        min-height: 300px;

        &_hasVideo {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include narrow-desktop-plus {
                padding: 32px 62px;
            }

            @include desktop {
                padding: 62px 62px;
            }
        }

        &_hasImage {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include narrow-desktop-plus {
                padding: 32px 62px;
            }

            @include desktop {
                padding: 62px 62px;
            }
        }

        &_isVideoLoading, /* it has video, and that video is loading */
        &_noImageBlock /* no video, no image */ {
            @include narrow-desktop-plus {
                padding: 32px 62px;
            }

            @include desktop {
                padding: 62px 62px;
            }
        }
    }

    &-HeadText {
        position: relative;

        &_noImageBlock {
            margin: 0 auto;
            max-width: 654px;
        }

        &_hasImageBlock {
            margin: 0 auto;
            max-width: 654px;

            @include narrow-desktop-plus {
                position: relative;
            }
        }
    }

    &-Title {
        font-style: normal;
        color: #{$white};
        margin-block-end: 24px;
    }

    &-Description {
        opacity: 0;
        animation: appear-nice 1.25s cubic-bezier(.19,1,.22,1) forwards;

        &_isAnimationEnded {
            opacity: 1;
            animation: none;
        }
    }

    &-Description,
    &-Description p,
    &-Description div,
    &-Description div p {
        color: #{$white};
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.26rem; // 20.16px

        @include narrow-desktop-plus {
            font-size: 1.125rem; // 18px
            line-height: 1.625rem; // 26px
        }
    }

    &-ImageContainer {
        grid-area: image;
        overflow: hidden;
        background-color: #{$default-primary-base-color};
    }

    &-ImageBackground {
        display: none;
        visibility: hidden;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;

        @include narrow-desktop-plus {
            display: block;
            visibility: visible;
        }
    }

    &-Image {
        max-width: 100%;
        height: auto;
        width: 100%;
        object-fit: contain;
        display: block; // to get rid of the gap under the inline-image

        @include narrow-desktop-plus {
            display: none;
        }
    }
}
