/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.CheckoutDeliveryOption {
    flex-direction: column;

    &-DPDInfo {
        background-color: var(--light-green-color);
        padding-block: 4px;
        padding-inline: 8px;
        font-size: 0.875rem;
        width: 100%;

        div {
            color: $default-primary-base-color;
        }
    }

    &-ButtonFieldsWrapper {
        border: 1px solid var(--checkout-grey-color);
        width: 100%;

        &:hover {
            border-color: var(--color-black);
        }
    }

    &_isDPD {
        border: none;

        &:hover {
            border-color: var(--checkout-grey-color);
        }
    }

    &-MCShippingHeading {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    &-MCShippingWithDropdown {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
