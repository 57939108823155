/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CookiePopup {
    position: fixed;
    width: 100%;
    height: 100%;
    inset-block-start: 0;
    opacity: 1;
    background-color: transparent;
    margin-inline: 0;
    padding: 32px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1500;

    &-Wrapper {
        background-color: var(--light-beige);
        max-width: 484px;
        z-index: 2;
        opacity: 1;
        width: 100%;
        padding: 52px 16px;
        margin-block-end: auto;

        @include desktop {
            margin-block: auto;
            padding: 52px 32px;
        }

        @include tablet {
            margin-block: auto;
        }

        @media (min-width: 1440px) {
            max-width: min-content;
        }
    }

    &-Overlay {
        position: fixed;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: var(--primary-dark-color);
        opacity: 0.2;
    }

    &-Content {
        color: var(--primary-dark-color);
        text-align: start;
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-block-end: 24px;

        &::after {
            display: none;
        }

        .CookieContent {
            &-Heading {
                font-size: 0.875rem;
                line-height: 1.125rem;
                font-weight: 600;
                text-transform: uppercase;
                margin-block-end: 0;
                letter-spacing: .08em;
            }

            &-Text {
                font-size: 1rem;
                margin-block-end: 0;
                white-space: pre-line;

                a {
                    font-size: 1rem;
                    font-weight: 400;
                }
            }

            &-Link {
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }

    &-Buttons {
        display: flex;
        gap: 8px;
        justify-content: flex-start;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }

        @include mobile {
            width: 100%;
        }
    }

    &-CTA {
        color: white;
        margin-inline-start: 0;
        margin-block-start: 0;
        font-weight: 600;
        font-size: 0.875rem;
        letter-spacing: 0.08em;
    }
}
