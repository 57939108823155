/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.BouncingArrow {
    -moz-animation: bounce 4s infinite;
    -webkit-animation: bounce 4s infinite;
    animation: bounce 4s infinite;

    @keyframes bounce {
        0%, 20%, 50%, 70%, 100% {
            -moz-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
        }

        30% {
            -moz-transform: translateY(5px);
            -ms-transform: translateY(5px);
            -webkit-transform: translateY(5px);
            transform: translateY(5px);
        }

        60% {
            -moz-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
        }
    }
}
