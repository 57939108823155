/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */


:root {
    // vvv for easier control of the popups z-index.
    --gdpr-cookie-bar-z-index: 400;
    --gdpr-settings-modal-z-index: 1400;
    --gdpr-cookies-details-z-index: 1800;
}

.GDPR {
    &-Popup {
        // --- vvv default values --- start //
        --gdpr-background-color: #a5a5a5; // todo: find where is it being used in the original extension
        --gdpr-cookies-bar-location: flex-end;
        --gdpr-links-color: var(--link-color);
        --gdpr-policy-text-color: var(--paragraph-color);

        --gdpr-accept-btn-order: 1;
        --gdpr-accept-btn-color: var(--button-background);
        --gdpr-accept-btn-hover-color: var(--button-hover-background);
        --gdpr-accept-btn-text-color: var(--button-color);
        --gdpr-accept-btn-text-hover-color: var(--button-hover-color);

        --gdpr-decline-btn-order: 2;
        --gdpr-decline-btn-color: var(--hollow-button-background);
        --gdpr-decline-btn-color-hover: var(--hollow-button-hover-background);
        --gdpr-decline-btn-text-color: var(--hollow-button-color);
        --gdpr-decline-btn-text-hover-color: var(--hollow-button-hover-color);

        --gdpr-settings-btn-order: 3;
        --gdpr-settings-btn-color: var(--hollow-button-background);
        --gdpr-settings-btn-hover-color: var(--hollow-button-hover-background);
        --gdpr-settings-btn-text-color: var(--hollow-button-color);
        --gdpr-settings-btn-text-hover-color: var(--hollow-button-hover-color);
        // --- ^^^ default values  --- end //
        align-items: flex-end;
        z-index: var(--gdpr-cookie-bar-z-index);

        .Popup-Content {
            width: 100vw;
            max-width: unset;
            border-radius: unset;
            padding: 50px; // copied from the magento2 extension.
        }


        .Popup-Header {
            display: none;
        }
    }

    &-ButtonsWrapper {
        display: flex;
        gap: 24px;
    }

    &-Button {
        &_Accept {
            order: var(--gdpr-accept-btn-order);
            background-color: var(--gdpr-accept-btn-color);
            color: var(--gdpr-accept-btn-text-color);

            &:hover {
                background-color: var(--gdpr-accept-btn-hover-color);
                color: var(--gdpr-accept-btn-text-hover-color);
            }
        }

        &_Decline {
            order: var(--gdpr-decline-btn-order);
            background-color: var(--gdpr-decline-btn-color);
            color: var(--gdpr-decline-btn-text-color);

            &:hover {
                background-color: var(--gdpr-decline-btn-hover-color);
                color: var(--gdpr-decline-btn-text-hover-color);
            }
        }

        &_Settings {
            order: var(--gdpr-settings-btn-order);
            background-color: var(--gdpr-settings-btn-color);
            color: var(--gdpr-settings-btn-text-color);

            &:hover {
                background-color: var(--gdpr-settings-btn-hover-color);
                color: var(--gdpr-settings-btn-text-hover-color);
            }
        }
    }
}
