@keyframes slide-from-left {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-to-left {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes appear-nice-left {
    0% {
        opacity: 0;
        transform: translate3d(30px,0,0);
    }

    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}

@keyframes appear-nice {
    0% {
        opacity: 0;
        transform: translate3d(0,30px,0);
    }

    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}

@keyframes disappear-nice {
    0% {
        opacity: 1;
        transform: translateZ(0);
    }

    100% {
        opacity: 0;
        transform: translate3d(0,30px,0);
    }
}

@keyframes discountSlide {
    0%,
    25% {
        inset-block-start: 2px;
    }

    35%,
    55% {
        inset-block-start: -78px;
    }

    65%,
    85% {
        inset-block-start: -160px;
    }

    95%,
    100% {
        inset-block-start: 2px;
    }
}

@keyframes appear-nice {
    0% {
        opacity: 0;
        transform: translate3d(0,30px,0);
    }

    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}


@keyframes appear-nice-left {
    0% {
        opacity: 0;
        transform: translate3d(30px,0,0);
    }

    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}
