/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.PasswordChangePage {
    &-Wrapper {
        padding: 155px 0;

        @include desktop {
            width: 464px;
        }

        h1 {
            font-family: $font-Klavika;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 126%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            margin-block-end: 16px;
            height: 18px;
        }

        @include mobile {
            padding: 70px 28px;

            h1 {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 126%;
                letter-spacing: 0.08em;
                text-transform: uppercase;
            }
        }

        input {
            padding-inline-start: 16px;
            padding-block-start: 8px;
            background-color: #e7e7e7;
            box-shadow: 0 1px 0 rgba(var(--input-box-shadow-color),.25);
            -webkit-border-after: none;
            border-bottom: none;
        }

        .Field {
            margin-block-start: 8px;

            &-InputWrapper {
                height: 52px;

                @include desktop {
                    width: 464px;
                }

                button {
                    position: absolute;
                    height: 24px;
                    width: 24px;
                    background-color: transparent;
                    border-style: none;
                    padding-inline-start: unset;
                    padding-inline-end: unset;
                    margin-block-start: 6px;

                    &:not([disabled]) {
                        &:hover {
                            background-color: unset;
                            border-color: unset;
                            border-radius: unset;
                            color: unset;
                            height: unset;
                            padding-inline-start: unset;
                            padding-inline-end: unset;
                            margin-block-start: 6px;
                        }
                    }
                }
            }
        }

        button {
            width: 100%;
        }
    }
}
