ul {
    li {
        margin-block-end: 5px;
        list-style-position: outside;
        list-style-type: disc;

        &::before {
            content: none;
        }

        &::marker {
            font-size: 0.875rem;
        }
    }
}
