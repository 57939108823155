/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MessagePage {
    min-height: calc(100vh - var(--current-header-height) - var(--breadcrumbs-height));
    margin-block-end: 0;
    margin-block-start: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 2.625rem;
        font-weight: 400;
        color: var(--primary-base-color);
        line-height: 1;

        @include desktop {
            font-size: 3.25rem;
        }
    }

    &-ButtonWrapper {
        color: $white;
    }

    &-Button {
        margin-block-start: 24px;
    }

    &-Text {
        font-size: 1rem;
        line-height: 4rem;
        font-weight: 700;
        margin-block-start: 1rem;

        @include desktop {
            font-size: 1.25rem;
        }
    }

    &-Wrapper {
        text-align: center;
    }
}

