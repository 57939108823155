/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.Blog {
    --blog-background: $gray-background-color;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--blog-background);
    min-height: 100vh;

    &-Title {
        width: 100%;
        height: 40px;
        display: inline-block;
    }

    &-Content {
        &_isLoading {
            min-height: 100vh;
        }
    }

    .ContentWrapper {
        width: 100%;
        max-width: 1560px;
    }

    .InstagramSection {
        padding-inline: 0;

        &-Header {
            padding-inline: 0;
        }

        .Slider-Wrapper {
            padding-inline: 0;
        }
    }

    .Loader {
        background-color: var(--blog-background);
    }

    .Image_isPlaceholder {
        height: calc(100vh - var(--current-header-height));
    }
}
