/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

body .VideoPopup {
    &_isVisible {
        padding: 0;
    }

    &-VideoPlayer {
        @include desktop {
            max-width: 100vw;
            width: 100vw;
            height: 100vh;
        }
    }

    &-PlayerContent {
        .vimeo {
            &-loading {
                display: none;
            }
        }
    }

    .Popup {
        &-Content {
            padding: 0;
        }

        &-CloseBtn {
            width: 56px;
            height: 56px;
            background-color: $black;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                stroke: $white;
            }
        }
    }
}
