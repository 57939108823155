@keyframes zoomItBig {
    15%,
    40%,
    75%,
    100% {
        opacity: .7;
        -webkit-transform-origin: center center;
    }

    15% {
        -webkit-transform: scale(1.5, 1.3);
    }

    40% {
        -webkit-transform: scale(0.9, 0.9);
    }

    75% {
        -webkit-transform: scale(1.09, 1);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1, 1);
    }
}

@keyframes zoomItSmall {
    15%,
    40%,
    75%,
    100% {
        -webkit-transform-origin: center center;
    }

    15% {
        -webkit-transform: scale(1.5, 1.3);
    }

    40% {
        -webkit-transform: scale(0.9, 0.9);
    }

    75% {
        -webkit-transform: scale(1.09, 1);
    }

    100% {
        -webkit-transform: scale(1, 1);
    }
}

@keyframes feedback {
    0% {
        height: 0px;
        width: 0px;
    }

    100% {
        height: 400px;
        width: 400px;
    }
}

@keyframes L_circle_rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes L_stroke_rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(1080deg);
    }
}

@keyframes L_stroke_fix {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(135deg);
    }

    100% {
        transform: rotate(270deg);
    }
}

@keyframes L_stroke_left_grow {
    0% {
        transform: rotate(-5deg);
    }

    50% {
        transform: rotate(-140deg);
    }

    100% {
        transform: rotate(-5deg);
    }
}

@keyframes L_stroke_right_grow {
    0% {
        transform: rotate(5deg);
    }

    50% {
        transform: rotate(140deg);
    }

    100% {
        transform: rotate(5deg);
    }
}

@keyframes discountSlide {
    0%,
    25% {
        inset-block-start: 2px;
    }

    35%,
    55% {
        inset-block-start: -76px;
    }

    65%,
    85% {
        inset-block-start: -150px;
    }

    95%,
    100% {
        inset-block-start: 2px;
    }
}

@keyframes wishlistLoader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
