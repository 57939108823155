/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@mixin key-treasures-image-mixin() {
    max-width: 900px;
    max-height: 608px;
}

.KeyTreasures {
    margin-block-start: 72px;

    @include mobile {
        padding: 0;
    }

    @include after-mobile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "thelist theimage";
        grid-gap: 0 52px;
        gap: 0 52px;
        margin-block-start: 118px;
    }

    @include narrow-desktop-plus {
        grid-template-columns: 452px 1fr;
    }

    @include widescreen {
        grid-gap: 0 80px;
        gap: 0 80px;
    }

    &-ImageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area: theimage;
        position: relative;
        overflow: hidden;
        width: 100%;

        @include key-treasures-image-mixin();

        @include mobile {
            padding-inline: 0;
        }

        @include desktop {
            inset-block-start: 50px;
        }

        &_hasVideo {
            // video has absolute position, so without this the block will have zero height
            // string interpolation is needed to fight the SASS bugs
            // https://github.com/sass/node-sass/issues/2815
            padding-block-end: #{"min(100%, 608px)"}; /* Aspect Ratio - square. this will be updated from code */
        }

        &-Image, iframe {
            object-position: 50% 0%;
            width: 100%;
            height: 100%;
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            inset-block-end: 0;
            inset-inline-end: 0;

            @include key-treasures-image-mixin();
        }
    }



    &-MainBlock {
        grid-area: thelist;

        @include after-mobile {
            padding-block-start: 0;
            max-width: 452px;
        }
    }

    &-Title {
        font-size: .875rem; // 14px
        font-weight: 600;
        letter-spacing: .08rem;
        margin-block-end: 8px;
        text-transform: uppercase;
        color: $black;

        @include mobile {
            margin-block-start: 0;
        }
    }

    &-Subtitle {
        font-family: $font-Prospectus-Pro-Cyrillic-Italic;
        font-weight: 400;
        color: $black;
        display: block;

        @include after-mobile {
            font-size: 2.875rem; // 52px
        }

        @include mobile {
            font-size: 2.625rem; // 42px
        }
    }

    &-IngredientsList {
        font-size: .875rem; // 14px
        font-weight: 600;
        margin-block-start: 26px;
        border-top: 1px solid rgba(29, 30, 28, .16);

        li {
            align-items: center;
            border-bottom: 1px solid rgba(29, 30, 28, .16);
            display: flex;
            line-height: 2.625rem;
            height: 41px;
            margin-block-end: 0;
            letter-spacing: .08rem;

            &::before {
                content: unset;
            }

            /* on-hover animation */

            &::after {
                content: '';
                position: absolute;
                inset-block-end: -1px;
                inset-inline-start: 0;
                width: 0;
                height: 1px;
                background-color: var(--color-black);
                transition: width 0.6s;
            }

            &:hover, &:focus {
                &::after {
                    @include after-mobile {
                        width: 100%;
                    }
                }
            }
        }

        a {
            font-size: $font-small-size;
            line-height: $lh-medium;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: 600;

            &:visited {
                color: var(--link-color);
            }

            &:hover {
                color: var(--color-black);
            }
        }
    }
}
