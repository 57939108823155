/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.PaypalMessages {
    max-width: var(--content-wrapper-width);
    padding-inline: 32px;
    margin-inline: auto;

    @include mobile {
        margin-block-start: calc(var(--header-nav-height) + var(--category-page-miscellaneous-height));
        padding: 14px;
    }
}

.HomePage {
    .PaypalMessages {
        margin-block-start: calc(var(--header-total-height) + 12px);
    }
}

.CategoryPage {
    .PaypalMessages {
        span {
            // vvv override default max-width to stretch banner on whole category page
            /* stylelint-disable-next-line declaration-no-important */
            max-width: inherit !important;
        }
    }
}

.CartPage {
    .PaypalMessages {
        @include mobile {
            margin-block-start: calc(var(--header-total-height) + 14px);
            padding: 0;
        }
    }
}
