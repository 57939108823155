/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.ProductActions {
    &-GiftWrapButton {
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: transparent;

        &.Button:not([disabled]):hover {
            border-color: var(--primary-base-color);
        }

        span {
            flex: 2;
            text-align: start;
            font-weight: 700;
            font-size: 14px;
            color: var(--primary-base-color);

            &:hover {
                color: var(--primary-dark-color);
            }

            @include mobile {
                inset-block-end: 0;
            }
        }

        .Image {
            padding: 0;
            width: 20px;
            height: 20px;
            margin-inline-end: 10px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    @include mobile {
        &-ActionsWrapper {
            padding-block-start: 30px;
        }

        &-Section {
            &_type {
                &_giftWrap {
                    position: absolute;
                    padding-block-start: 30px;
                }
            }
        }
    }
}
