/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.OrdersAndReturns {
    display: flex;

    &.-form {
        display: flex;
        justify-content: space-between;
    }

    .OrdersAndReturns-HiddenContent {
        display: flex;
        flex-direction: row;
        column-gap: 30px;
        border-style: !important double;
        padding: 10px;
    }
}

.searchResult {
    &column {
        display: flex;
        flex-direction: column;
        margin: 10% ;
    }
}

.buttonsPart {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-block-start: 10px;
}

.lastField {
    padding-block-end: 10px;
}
