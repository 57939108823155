/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.GDPRCookiesDetails {
    z-index: var(--gdpr-cookies-details-z-index);

    &-Table {
        display: flex;
        flex-direction: column;
        // vvv to prevent the table from covering the whole screen when it has a lot of data.
        max-height: 50vh;
        overflow-y: auto;
        position: relative;

        thead > tr {
            position: relative;
        }

        th {
            // vvv to prevent the header from appearing below the cells when scrolling
            z-index: 1;
            position: sticky;
            inset-block-start: 0px;
        }

        &::-webkit-scrollbar-track {
            background-color: white;
            box-shadow: none;
        }

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background: #e0e0e0;
        }
    }

    .Button {
        margin-block-start: 24px;
        width: 100%;
    }

    .Popup-CloseBtn {
        display: none;
    }
}
