.PageBuilder {
    &-tabs {
        li::before {
            display: none;
        }

        .tabs-navigation {
            display: none;
        }

        .tab {
            &-item {
                display: none;

                &.active {
                    display: flex;
                }

                .pagebuilder-column-line {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    @include desktop {
                        flex-direction: row;
                    }

                    .pagebuilder-column {
                        width: 100%;
                        color: $black;

                        @include desktop {
                            width: 42.3%;
                            margin-block-start: 0;
                        }

                        &:first-child {
                            margin-block-start: 0;
                            width: 100%;

                            &:empty {
                                /* stylelint-disable-next-line declaration-no-important */
                                display: none !important;
                            }

                            @include desktop {
                                width: 52%;
                                margin-inline-end: 6.061%;
                            }

                            figure {
                                display: flex;
                                justify-content: center;
                                width: 100%;
                                height: 100%;

                                @include desktop {
                                    justify-content: flex-start;
                                }

                                img {
                                    width: 328px;
                                    height: 397px;
                                    object-fit: cover;
                                    margin-block-end: 32px;

                                    @include desktop {
                                        width: 100%;
                                        height: auto;
                                        margin-block-end: 0px;
                                    }
                                }
                            }
                        }

                        h2 {
                            font-size: 2.625rem;
                            font-weight: 400;
                            line-height: 100%;
                            margin: 0 0 52px;
                            font-family: $font-Prospectus-Pro-Cyrillic-Italic;

                            @include desktop {
                                font-size: 3.25rem;
                            }
                        }

                        p,
                        div {
                            font-weight: 400;
                            font-size: 1rem;
                            line-height: 142%;

                            li {
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 142%;
                                list-style-type: disc;
                                list-style-position: outside;

                                @include desktop {
                                    /* stylelint-disable-next-line declaration-no-important */
                                    font-size: 1.125rem !important;
                                }
                            }

                            @include desktop {
                                /* stylelint-disable-next-line declaration-no-important */
                                font-size: 1.125rem !important;
                            }
                        }

                        .ExpandableContent {
                            &-Heading {
                                /* stylelint-disable-next-line declaration-no-important */
                                font-size: 0.875rem !important;
                            }

                            &-Content {
                                p,
                                div {
                                    /* stylelint-disable-next-line declaration-no-important */
                                    font-size: 1rem !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
