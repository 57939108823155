/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.pagebuilder {
    &-column {
        .ExpandableContent {
            &-Heading {
                font-size: 16px;
                cursor: pointer;
            }

            &-Content {
                &_useOnDesktop {
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;
                }

                &_isContentExpandedDesktop {
                    opacity: 1;
                    max-height: 100%;
                }
            }

            &_useOnDesktop {
                cursor: pointer;
                border-block-start: 1px solid var(--expandable-content-divider-color);

                &:last-of-type {
                    border-block-end: 1px solid var(--expandable-content-divider-color);
                }

                .AddIcon,
                .MinusIcon,
                .ChevronIcon {
                    width: var(--toggle-button-size);
                    height: var(--toggle-button-size);
                }

                .ExpandableContent {
                    &-Button {
                        padding-block: 0.875rem;
                    }

                    &-Content {
                        @include desktop {
                            margin-block-start: 0;
                        }

                        .pagebuilder-button {
                            &-primary {
                                color: #000;
                            }

                            &-secondary {
                                background: transparent;
                                border-radius: 2px;
                                border: 2px solid var(--primary-base-color);
                                color: var(--primary-base-color);

                                &:hover {
                                    background: none;
                                }
                            }

                            &-link {
                                border-bottom: var(--link-underline);
                                color: var(--link-color);
                                font-size: 14px;
                                font-weight: 700;
                                margin-block-end: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
