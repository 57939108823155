/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.GDPRSettingsModal {
    &-Wrapper {
        z-index: var(--gdpr-settings-modal-z-index);
        display: flex;
        justify-content: center;

        .GroupData {
            &-Wrapper {
                .Button {
                    &_isHollow {
                        padding: unset;
                        height: unset;
                    }
                }
            }

            &-Header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        .Popup {
            &-Content {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &-Header {
                display: none;
            }
        }
    }

    &-DoneButton {
        margin-block-start: 24px;
        justify-self: center;
    }
}
