/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

// the style for footer CMS block from admin

@import "../../style/abstract/abstract";

.FooterColumn {
    &-Button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-block: 4px;
        margin-block-end: 16px;

        @include desktop {
            padding-block: 0;
            margin-block-end: 24px;
        }

        > .ChevronIcon {
            fill: var(--color-white);
            width: 18px;
            height: 18px;
        }
    }

    &-Heading {
        line-height: 1.5rem;
        margin-inline-end: 0.625rem;
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
        word-break: normal;
        cursor: pointer;

        @include desktop {
            margin-inline-end: 0px;
            width: 196px;
        }

        @include wide-desktop {
            cursor: unset;
        }
    }

    &-Content {
        // Disabled as it prevents z-index use inside
        // will-change: max-height, padding, opacity;
        transition-property: opacity;
        transition-duration: 200ms;
        max-height: 0;
        overflow: hidden;
        opacity: 0;

        &_isContentExpanded {
            opacity: 1;
            max-height: 100%;
        }

        @include desktop {
            margin-block-start: 12px;
        }

        @include wide-desktop {
            opacity: 1;
            max-height: 100%;
        }
    }
}
