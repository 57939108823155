/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SuccessPage {
    &-Action {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-block-end: 220px;
        padding: 0px 8px;
    }

    &-CreatePasswordSuccess {
        height: 194px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-block-start: 200px;

        @include desktop {
            width: 464px;
        }

        .CheckmarkIcon {
            position: unset;
            margin-block: 0;
            width: 42px;
            height: 42px;
        }

        h3 {
            text-transform: uppercase;
            font-size: 0.875rem;
            font-weight: 600;
            padding-block-start: 10px;
            margin-block-start: 0px;
        }

        p {
            text-align: center;
        }
    }

    &-Button {
        width: 100%;

        @include mobile {
            margin-block-start: 14px;
        }
    }
}
