/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/* stylelint-disable declaration-no-important */
.AmastyDiscount {
    &-Main {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: min-content 1fr;
        gap: 0px 0px;
        margin-block: 0px;
        grid-template-areas:
            "Rule Price"
            "ChildRule ChildRule";

        dl & {
            margin-bottom: 0;
        }

        &::before {
            content: none;
        }
    }

    &-ShowAllButton {
        width: min-content !important;
        padding-inline-start: 0.313rem !important;

        &:hover {
            padding-inline-start: 0.313rem !important;
        }

        @include mobile {
            .ChevronIcon {
                height: 0.875rem;
                width: 0.875rem;
            }
        }
    }

    &-ChildRules {
        grid-area: ChildRule;
        transition: .5s;
        overflow: hidden;
        height: 0;

        &_isOpen {
            height: fit-content;
            padding-block-start: 0.25rem;
        }

        .CheckoutOrderSummary {
            &-SummaryItem {
                padding: 0;
                margin-block: 0.5rem;
                font-size: 0.875rem;

                &:first-child {
                    margin-block-start: 0;
                }

                &:last-child {
                    margin-block-end: 0;
                }
            }

            &-Text {
                font-size: 0.875rem;
                max-width: 16.5rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-block: 0px;
            }
        }
    }

    &-Price {
        grid-area: Price;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    &-Title {
        strong {
            font-weight: 600;
        }

        grid-area: Rule;
        user-select: none;
        margin-bottom: 0;
        display: inline-flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 600;

        .ChevronIcon {
            fill: $black;
        }

        @include hoverable {
            cursor: pointer;
            
            &:hover {
                strong {
                    color: $default-primary-base-color;
                }

                .ChevronIcon {
                    fill: $default-primary-base-color;
                }
            }
        }
    }
}
