/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

// Amasty Gdpr Settings Page Styles
// _____________________________________________
//  Variables
//  ---------------------------------------------

$gdpr-icon-policy__background__active: url('./images/policy_active.svg') no-repeat center;
$gdpr-icon-anonymise__background__active: url('./images/anonymise_active.svg') no-repeat center;
$gdpr-icon-download__background__active: url('./images/download_active.svg') no-repeat center;
$gdpr-icon-delete__background__active: url('./images/delete_active.svg') no-repeat center;
$gdpr-icon-policy__background: url('./images/policy.svg') no-repeat center;
$gdpr-icon-anonymise__background: url('./images/anonymise.svg') no-repeat center;
$gdpr-icon-download__background: url('./images/download.svg') no-repeat center;
$gdpr-icon-delete__background: url('./images/delete.svg') no-repeat center;
$gdpr-checkbox-label__background__color: #e8f6ff;
$gdpr-icon-dpoinfo__background: url('./images/officer.svg') no-repeat center;
$gdpr-icon-dpoinfo__background__active: url('./images/officer_active.svg') no-repeat center;

.PrivacySettingsPage {
    display: inline-flex;
    width: 100%;
    min-height: 80%;

    &-LoaderWrapper {
        display: flex;
        justify-items: center;
        align-items: center;
        min-height: 100%;
        width: 100%;
    }

    .ExpandableContent-Content {
        .PrivacySetting-HiddenContent {
            display: none;
        }

        &_isContentExpanded {
            .PrivacySetting-HiddenContent {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .gdpr-checkbox-container {
        margin: 8px;
        max-width: 220px;
        position: relative;

        .gdpr-title {
            color: #333;
            font-size: 16px;
            font-weight: 600;
            inset: 80% 50%;
            min-width: 180px;
            position: absolute;
            text-align: center;
            transform: translate(-50%, -50%);
            transition: color .3s ease-in;
        }
    }
}

