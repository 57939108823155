/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.AddToBag {
    &-Button {
        border: 1px solid var(--primary-base-color);
        color: var(--primary-base-color);
        width: 100%;
        height: 38px;
        cursor: pointer;
        font-weight: 600;
        inset-block-start: 25px;

        &:hover {
            color: var(--color-white);
            background-color: var(--primary-base-color);
            border-color: transparent;
        }
    }
}
