/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.StoreItem {
    &-Item {
        font-size: 0.875rem;
        padding: 0;
        white-space: nowrap;
        cursor: pointer;
        position: relative;
        color: var(--color-white);
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 18px;
        margin-block-end: 4px;
        text-transform: uppercase;
        text-decoration: none;
        display: block;
        width: 100%;
        text-align: center;

        &:active, &:focus, &:visited {
            color: var(--color-white);
        }

        @include ultra-wide-desktop {
            line-height: unset;
        }

        &:hover::after,
        &:focus::after {
            width: 100%;
            inset-inline-end: auto;
            inset-inline-start: 0;
        }

        @include mobile {
            font-size: 14px;
        }

        &_isDisabled {
            pointer-events: none;
            opacity: 0.6;
        }

        &::after {
            @include desktop {
                content: '';
                position: absolute;
                inset-block-end: 0;
                inset-inline-start: auto;
                inset-inline-end: 0;
                width: 0;
                height: 1px;
                background-color: var(--color-white);
                transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
            }
        }

        &:hover {
            color: var(--color-white);

            @include desktop {
                background: var(--primary-base-color);
            }
        }
    }
}
