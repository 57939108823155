@font-face {
    // Regular
    font-family: 'Roboto Condensed';
    src: url(../fonts/RobotoCondensed.woff2) format('woff2'), url(../fonts/RobotoCondensed.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    // Bold
    font-family: 'Roboto Condensed';
    src: url(../fonts/RobotoCondensed-Bold.woff2) format('woff2'), url(../fonts/RobotoCondensed-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
