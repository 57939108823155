/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.GiftWrapPopup.Popup {
    padding: 0;

    @include desktop {
        padding-block: 32px 64px;
    }

    .Popup-Content {
        max-height: 100svh;
        height: 100svh;

        @include tablet {
            padding-inline: 124px;
        }

        @include desktop {
            max-width: 484px;
            max-height: calc(100vh - 64px);
            height: unset;
        }

        p {
            width: initial;
            margin-block-end: 0;
        }

        .GiftWrapPopup {
            &-Description {
                width: 100%;
                text-align: center;
                margin-block-end: 24px;
            }

            &-TabsNavigation {
                display: flex;
                gap: 36px;
                border-block-end: 1px solid rgba(var(--black-rgb-color), 0.15);
                margin-block-end: 16px;
            }

            &-Tab {
                padding-block-end: 8px;

                input {
                    display: none;
                }

                label {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 0.875rem;
                    line-height: 1.125rem;
                    letter-spacing: 0.08em;
                    color: $default-primary-base-color;

                    &:hover {
                        cursor: pointer;
                    }
                }

                &_isActive {
                    border-block-end: 1px solid $default-primary-base-color;

                    label {
                        color: $black;
                    }
                }
            }
        }

        .Postcards {
            .PostCard-Message {
                margin-block-start: 20px;

                p {
                    margin: 0;
                }
            }
        }

        .Postcards, .Wrappers {
            .GiftWrapButton {
                margin-block-start: 10px;
            }

            .Button {
                margin-block-start: 10px;
            }

            &-Missing {
                font-weight: 700;
                font-size: 14px;
                text-align: center;
                padding-block: 30px;
                color: var(--primary-dark-color);
            }
        }

        .Field {
            &_type {
                &_textarea {
                    margin-block: 0;

                    textarea {
                        width: 100%;
                        background: transparent;
                        padding: 16px;
                        border: 1px solid $input-border-color;
                        resize: both;
                    }
                }
            }
        }

        .GiftWrap-Buttons {
            display: flex;
            margin-block-start: 24px;
            justify-content: center;
            gap: 16px;

            button {
                display: block;
                box-sizing: content-box;
                padding: 14px 24px;
                width: fit-content;
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.125rem;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                height: fit-content;
                border: none;
            }
        }
    }
}
