/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.SocialLoginButtons {
    width: 100%;

    &-Row {
        display: flex;
        column-gap: 8px;
    }

    &-Column {
        flex: 50%;
    }

    &-OrTitle {
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 1rem;
        line-height: 1.25rem;
        margin-block: 8px;
        color: $black;
        opacity: 0.5;
    }

    .SocialLoginButton {
        background-color: transparent;
        border-radius: 0;
        border-color: $default-primary-base-color;
        color: $default-primary-base-color;
    }
}
