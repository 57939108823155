a {
    font-size: inherit;
    font-weight: 400;
    text-decoration: underline;

    @include hoverable {
        &:hover {
            color: var(--color-black);
            text-decoration: none;
        }
    }

    &.Link {
        text-decoration: none;
    }
}

.link-light-hover {
    @include hoverable {
        &:hover {
            color: $text-light-grey;
            text-decoration: none;
        }
    }
}

.link-animation {
    position: relative;
    text-decoration: underline;
    font-weight: 600;

    &::before {
        content: '';
        position: absolute;
        inset-block-end: 0;
        inset-inline-end: 0;
        width: 0;
        height: 2px;
        background-color: var(--color-black);
        transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    }

    @include hoverable {
        &:hover {
            color: var(--color-black);

            &::before {
                inset-inline-end: 0;
                inset-inline-start: auto;
                width: 100%;
            }
        }
    }
}

.font-size-14 {
    font-size: 0.875rem;
    line-height: 1.125rem;
}

.font-size-16 {
    font-size: 1rem;
    line-height: 1.25rem;
}

.font-size-18 {
    font-size: 1.125rem;
    line-height: 1.625rem;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-600 {
    font-weight: 600;
}

.underline {
    text-decoration: underline;
}

.no-underline {
    text-decoration: none;
}

.underline-hover {
    @include hoverable {
        &:hover {
            text-decoration: underline;
        }
    }
}

.no-underline-hover {
    @include hoverable {
        &:hover {
            text-decoration: none;
        }
    }
}
