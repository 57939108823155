/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.ProductActions-Section {
    &_type {
        &_giftWrap {
            margin: 10px 0;

            .Note {
                font-weight: 700;

                &-Price {
                    padding-inline-start: 3px;
                }

                &-Label {
                    font-weight: 400;
                    padding-inline-end: 3px;

                    &::before {
                        content: "";
                        display: block;
                    }
                }
            }
        }

        @include mobile {
            &_giftWrap {
                padding-block-end: 3rem;
            }

            &_sku {
                margin-block-start: -3em;
            }
        }
    }
}
