/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.HtmlSitemap {
    &-ContentWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 30vh;

        @include mobile {
            gap: inherit;
        }
    }

    @include mobile {
       &-Wrapper {
            padding-top: 20px;
       }
    }

    a {
        padding-left: 30px;
    }

    &-CatalogTitle {
        ul {
            padding-left: 5vh;
        }
    }

    &-StoreList {
        display: flex;

        div {
            padding-left: 1vh;
        }
    }
}
