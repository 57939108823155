:root {
    --current-font-family: 'Klavika Basic', sans-serif;
}

html {
    &[lang="ru"] {
        --current-font-family: 'Roboto Condensed', sans-serif;
    }
}

body {
    font-family: var(--current-font-family);
}

main {
    @include mobile {
        // Removed navigation tabs height since we don't have those
        min-height: calc(100vh - var(--header-total-height) + 2 * var(--border-width));
    }
}
