/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.LandingPageNavigation {
    &-Button {
        display: none;
    }

    @include mobile {
        &-Button {
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
            margin-block-end: 0.625rem;
        }

        &-Button > button:disabled {
            pointer-events: none;
            opacity: .5;
        }
    }
}

.LandingPageStickyMenu {
    position: sticky;
    overflow: hidden;
}

.LandingPageNavigationItems {
    overflow-x: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.LandingPageSliderComponent {
    overflow: hidden;

    .sliderContent {
        transform: translateX(50% + var(--landing-slider-content-offset));
        transition: transform 0.3s ease;
    }
}

@include mobile {
    .LandingPage {
        &NavigationItem {
            padding-inline-end: 1.125rem;
        }

        &Sticky {
            &Menu {
                inset-block-start: -52px;
                transform: translateY(0);

                &_isStickyMenu {
                    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
                    background-color: $default-primary-base-color;
                    transform: translateY(calc(50% + 52px));
                    position: fixed;
                    z-index: 100;
                    color: $white;
                    inset-inline-start: 0px;
                    width: 100%;
                    padding: 0 10px;

                    .ChevronIcon {
                        fill: white;
                    }

                    .LandingPageNavigation {
                        border-bottom: .06rem solid rgba($white, 0.2);
                        padding: 25px 0 0 0;

                        &Item {
                            &-text {
                                color: $white;
                            }

                            & a[elem="Link-Current"] {
                                &::after {
                                    border-bottom-color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

