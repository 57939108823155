/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.FacePLPPromoBanner {
    &-Wrapper {
        position: relative;
        grid-column: 1 / 3;

        @include tablet {
            grid-column: 2 / 4;
        }

        @include mobile {
            margin-block-end: 32px;
        }
    }

    &-Image {
        .Image {
            display: block;

            img {
                object-fit: fill;
            }

            @include desktop {
                padding-block-end: 0;
                margin-block-end: 36px;

                img {
                    position: relative;
                }
            }

            @include tablet {
                padding-block-end: 50vw;

                img {
                    object-fit: cover;
                }
            }

            @include large-tablet {
                padding-block-end: 53vw;
            }
        }
    }

    &-Content {
        position: absolute;
        inset-inline-end: 42px;
        max-width: 328px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include mobile {
            position: relative;
            inset-block-start: 0;
            inset-inline-end: 0;
            background-color: $default-primary-base-color;
            max-width: unset;
            padding: 32px 16px;
            align-items: flex-start;
        }

        @include after-mobile {
            inset-block-start: 50%;
            transform: translateY(-50%);
        }
    }

    &-Title,
    &-Text {
        color: $white;
    }

    &-Title {
        font-size: 2.625rem;
        line-height: 2.625rem;
        margin-block-end: 24px;
        margin-block-start: 0px;

        @include tablet {
            font-size: 2.25rem;
            line-height: 2.25rem;
        }

        @include mobile {
            font-size: 2rem;
            line-height: 2rem;
        }
    }

    &-Text {
        font-size: 1.125rem;
        /* stylelint-disable-next-line declaration-no-important */
        margin-block-end: 24px !important;
        color: $white;

        @include tablet {
            font-size: 1rem;
        }

        @include mobile {
            font-size: 1rem;
        }
    }

    & &-Link {
        display: flex;
        flex-direction: row;
        width: max-content;
        height: unset;
        margin-block: 0px;
        padding: 14px 24px;
        border: 2px solid $white;
        background: transparent;
        color: $white;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 126%;
        text-align: center;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        cursor: pointer;
        text-decoration: none;

        @include mobile {
            width: max-content;
            height: unset;
            padding: 14px 24px;
        }

        &:visited {
            color: $white;
        }

        @include after-mobile {
            &:hover {
                border-color: transparent;
                color: $default-primary-base-color;
                background-color: $white;
            }
        }

        @include desktop {
            &.Button:hover {
                padding: 14px 24px;
                height: unset;
                background: $white;
                border-color: $white;
                color: $default-primary-base-color;
            }
        }
    }
}
