/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


.Loader+main {
    .IngredientsRoute-LoaderWrapper {
        .Loader {
            // when we load page by URL - there will be two loaders, one from system, the other from our components
            // (as the system loader will wait for all lazy() wrappers).
            // But when we transition from other pages by Link - there will be only the component's loder.
            // It's not possible to control the system Loader visibility, so we hide ours when the system loader is visible,
            display: none;
        }
    }
}

.IngredientsRoute {
    &-LoaderWrapper {
        min-height: 55vh; // to prevent the footer being sticked to the top of the screen

        .Loader {
            overflow: visible;
        }
    }
}
