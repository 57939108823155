/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Ingredient {
    @include desktop {
        margin-inline: auto;
    }

    &-PageWrapper {
        background: var(--light-beige);
        padding-block-start: 135px;

        @include desktop {
            padding-block-start: 155px;
        }
    }

    .Ingredient-ProductsWrapper { // we have 3 levels here intentionally - to increase specificity
        margin-block-start: 32px;

        @include after-mobile {
            margin-block-start: 52px;
        }

        @include desktop {
            padding: 0 60px;
            box-sizing: content-box;
            max-width: 1440px;
            margin: 52px auto 0 auto;
        }

        .ProductListPage {
            padding: 0 16px;
            grid-column-gap: 8px;
            margin: 0;
            border: 0;
            grid-template-columns: 1fr 1fr;
            display: grid;
            grid-auto-rows: max-content;

            @include after-mobile {
                grid-template-columns: repeat(4, 1fr);
            }

            @include desktop {
                box-sizing: content-box;
                padding: 0;
                grid-column-gap: 10px;
            }
        }
    }

    &-PageHeader {
        padding: 0 16px;

        @include narrow-desktop-plus {
            padding: 0 16px;
        }

        @include desktop {
            padding: 0 60px;
        }
    }

    .InstagramSection {
        @include desktop {
            box-sizing: content-box;
            max-width: var(--content-wrapper-width);
            margin-inline: auto;
        }

        @include tablet {
            padding-inline: 32px;
        }
    }
}
