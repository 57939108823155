/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.FieldSelect {
    &-AltPlaceholder {
        display: none;
        position: absolute;
        inset-block-start: 6px;
        inset-inline-start: 16px;
        z-index: 10;
        opacity: .5;
        color: $black;
        font-size: .875rem;

        .Field_hasError & {
            display: none;
        }

        .Field_isValid & {
            display: block;
        }

        .FieldSelect-Clickable_hasDefault & {
            display: block;
        }
    }

    &-Option {
        position: relative;

        &:hover::after,
        &:focus::after {
            width: 100%;
            inset-inline-end: auto;
        }

        &::after {
            height: 0px;
            inset-inline-end: 0;
            inset-inline-start: auto;
            width: 0;

            @include desktop {
                height: 1px;
            }
        }
    }
}
