/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.CustomPopup {
    position: fixed;
    width: 100%;
    height: 100%;
    inset-block-start: 0;
    opacity: 1;
    background-color: transparent;
    margin-inline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    &-Overlay {
        position: fixed;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: var(--primary-dark-color);
        opacity: 0.45;
    }

    &-Wrapper {
        background-color: var(--light-beige);
        max-width: 680px;
        z-index: 2;
        opacity: 1;
        width: 100%;
        padding: 0px;
        margin-block-start: auto;
        margin-block-end: auto;
    }

    &-Header {
        text-align: right;
        padding: 10px 10px 0;
    }

    &-Content {
        color: var(--primary-dark-color);
        text-align: start;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0px 32px 32px;

        &::after {
            display: none;
        }
    }

    &-Buttons {
        display: flex;
        gap: 8px;
        justify-content: flex-start;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }

        @include mobile {
            width: 100%;
        }
    }
    
    &-BottomImage {
        padding-block-end: 51.5%;
        margin-block-end: -8px;
    }    
}
