/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.AdditionalMenu {
    &-MenuWrapper {
        .Menu-Item {
            height: 100%;
            margin-block-end: 0;
            list-style-type: none;

            &Caption_type_main {
                font-style: normal;
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 126%;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: var(--header-link-color);
                margin-block-end: 0;

                &::before {
                    @include desktop {
                        content: "";
                        position: absolute;
                        inset-block-end: -1px;
                        inset-inline-end: 0;
                        width: 0;
                        height: 1px;
                        background-color: var(--header-link-color);
                        transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
                    }
                }

                &:hover::before {
                    @include desktop {
                        inset-inline-start: 0;
                        inset-inline-end: auto;
                        width: 100%;
                    }
                }

                &:hover {
                    @include desktop {
                        color: var(--header-link-color);
                    }
                }
            }
        }
    }

    &-ItemList {
        display: flex;
        flex-direction: row;
        padding-inline-start: 36px;
        gap: 36px;

        .Menu {
            &-Item {
                &:not(:first-of-type) {
                    margin-inline-start: 0;
                }
            }
        }
    }
}
