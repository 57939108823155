/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.GiftWrapCard {
    display: flex;
    gap: 12px;
    border: 1px solid $input-border-color;
    padding: 12px 10px 12px 12px;
    cursor: pointer;

    &-Wrapper {
        margin-block-end: 8px;
    }

    &-Promo {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: $black;
        background: $giftwrap-popup-notification-background-color;
        padding: 4px 8px;
    }

    &_isSelected, &:hover {
        border-color: $black;
    }

    &-Description {
        display: flex;
        margin-block-start: 8px;
        gap: 12px;

        p {
            color: rgba(var(--black-rgb-color), 0.5);
            font-size: 0.875rem;
            word-wrap: break-word;
        }
    }

    &-DescriptionText {
        width: 90% !important;
    }

    &-TaxInfo {
        display: flex;
        width: fit-content !important;
        min-width: fit-content !important;
        word-wrap: unset !important;
        justify-self: flex-end;
        font-size: 0.75rem !important;
        margin-block-start: -10px !important;
    }

    .Field [type="radio"] {
        + .input-control {
            width: 16px;
            height: 16px;
            border: 1px solid rgba(var(--black-rgb-color), 0.5);
            background: transparent;
            margin-inline-end: 0;
            min-width: unset;
            min-height: unset;
            transition: none;

            &::after {
                display: none;
            }
        }

        &:hover:not(:disabled) {
            + .input-control {
                border-color: $black;
            }
        }

        &:checked {
            + .input-control {
                border: 5px solid $black;
            }
        }
    }

    &-Image {
        width: 56px;
        height: 60px;
        min-width: 56px;
        min-height: 60px;
        padding: 0;
        margin-block-start: 0;
    }

    .Image {
        &-Content {
            height: 60px;
        }

        &-Image {
            object-fit: cover;
        }
    }

    &-Name {
        flex-grow: 1;
    }

    &-NameAndPrice {
        display: flex;
        margin-block-end: 8px;
        gap: 16px;
    }

    &-Price {
        text-align: end;
        word-break: keep-all;
        white-space: nowrap;
    }

    .Field {
        &_type {
            &_radio {
                margin: 0;
            }
        }

        &-InputWrapper {
            max-height: 16px
        }
    }

    &-Info {
        max-width: calc(100% - 28px);
        flex-grow: 1;
    }
}

.Postcards {
    .GiftWrapCard {
        &-Image {
            width: 100px;
            height: 60px;
            min-width: 100px;
            min-height: 60px;
        }
    }
}
