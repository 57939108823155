a {
    &:active, &:focus {
        &.Button {
            color: var(--button-color);

            &_isHollow {
                color: var(--hollow-button-color);
            }
        }
    }
}
