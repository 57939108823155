/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */

:root {
    @include desktop {
        --CMS-padding-inline: 60px;
    }

    @include ultrascreen-desktop {
        --CMS-padding-inline: 1.5rem;
    }

    @include widescreen-desktop {
        --CMS-padding-inline: 2.5rem;
    }

    @include medium-desktop {
        --CMS-padding-inline: 60px;
    }

    @include tablet {
        --CMS-padding-inline: 32px;
    }

    @include mobile {
        --CMS-padding-inline: 16px;
    }

    @include mobile-tablet {
        --CMS-padding-inline: 2rem;
    }
}

.Breadcrumbs ~ .Router-MainContent {
    .CmsPage {
        padding-block-start: 0;
    }
}

.CmsPage {
    background-color: var(--light-beige);
    padding-block-start: calc(35px + var(--current-header-height));

    &-Content {
        [data-content-type="row"]:not(.intro-video-row):not(.animate-ended) {
            opacity: 0;
        }

        .CmsPageContentWrapper {
            > * {
                opacity: 0;
            }
        }
    }

    .animate-in {
        animation: appear-nice 1.25s cubic-bezier(.19,1,.22,1) forwards;
    }

    .animate-ended {
        animation: none;
        opacity: 1;
    }

    @include desktop {
        margin-inline: auto;
    }

    @include mobile {
        margin-block-start: 0;
    }

    p:empty {
        display: none;
    }

    &-Heading {
        display: block;
    }

    &-Wrapper {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;
        padding-inline-end: 0;

        .pagebuilder {
            &-column {
                @include mobile {
                    // important needed to override default CMS styles
                    width: 100% !important;
                }

                &-line {
                    @include mobile {
                        // important needed to override default CMS styles
                        display: block !important;
                    }
                }
            }
        }
    }

    [data-content-type="row"][data-appearance="contained"] {
        box-sizing: content-box;
        max-width: var(--content-wrapper-width);
        padding-inline: var(--content-wrapper-padding-inline);

        @include tablet {
            padding-inline: 32px;
        }
    }

    ul {
        padding-inline-start: 40px;

        li.ProductCard::before {
            margin-inline: unset;
            padding-inline-end: unset;
        }
    }

    * {
        .LandingBannerButton {
            &:hover {
                background: $default-primary-dark-color !important;
                color: $white !important;
            }
        }
    }

    .AboutUsLandingIntro, .js-vimeo-player {
        pointer-events: none;
    }

    .InstagramSection {
        padding-inline: var(--CMS-padding-inline);

        &-Header {
            @include medium-tablet-only {
                padding-inline: 0;
            }
        }

        &-SliderWrapper {
            .InstagramSection-Slider {
                .Slider-Wrapper {
                    @include medium-tablet-only {
                        padding-inline: 0;
                    }
                }
            }
        }
    }
}

#html-body {
    .ContactPage {
        margin-block: 0;
        padding-block-start: 0;

        @media (min-width: 1560px) {
            padding-inline: 0;
        }

        p {
            margin-block: 0;
        }

        [data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
            max-width: unset;
        }

        .pagebuilder {
            &-column {
                width: 100%;

                @include desktop {
                    width: 50%;
                }

                &:first-child {
                    @include desktop {
                        max-width: 368px;
                    }
                }

                &:last-child {
                    @include desktop {
                        max-width: 536px;
                    }
                }

                &-group {
                    border-block-start: solid 1px rgba($black, 0.16);
                    padding-block-start: 52px;
                    padding-inline: 16px;

                    @include desktop {
                        padding-inline: 0;
                    }

                    @include tablet {
                        padding-inline: 0;
                    }
                }

                &-line {
                    flex-direction: column-reverse;
                    gap: 32px;
                    // important needed to override "display: block !important;" on line 42 for contact page
                    display: flex !important;

                    @include desktop {
                        flex-direction: row;
                        gap: 164px;
                    }

                    @include tablet {
                        flex-direction: row;
                        gap: 24px;
                    }
                }
            }
        }

        .ContentWrapper {
            padding-inline: 16px;

            @include desktop {
                padding-inline: 60px;
            }
        }

        &-Heading {
            font-size: 2.625rem;
            margin-block: 0 24px;
            height: unset;

            @include desktop {
                font-size: 3.25rem;
            }
        }

        &-SubHeading {
            margin-block-end: 24px;
        }

        &-ColumnWrapper {
            display: flex;
            flex-direction: column-reverse;
            gap: 32px;
            margin-block-end: 0;
            padding-block-start: 52px;
            border-block-start: 1px solid rgba(var(--black-rgb-color), 0.16);

            @include desktop {
                flex-direction: row;
                gap: 164px;
            }
        }

        &-Column {
            flex: 1;

            @include desktop {
                max-width: 536px;
            }

            &_isContent {
                margin-inline-end: 0;

                @include desktop {
                    margin-inline-end: 18px;
                    max-width: 368px;
                }
            }
        }

        .Button {
            width: 100%;
        }

        .InstagramSection {
            padding-block: 72px;
            margin-block: 0;

            @include desktop {
                padding-block: 120px;
            }

            &-Header {
                @include medium-tablet-only {
                    padding-inline: 0;
                }
            }

            .Slider-Wrapper {
                @include medium-tablet-only {
                    padding-inline: 0;
                }
            }
        }

        .ContactForm {
            .CheckmarkIcon {
                display: none;
            }
        }
    }

    .LandingWrapper,
    .LandingPageWrapper,
    .CMSWrapper,
    .ContactPage {
        max-width: 1440px;
    }

    .LandingBanner,
    .LandingPageWrapper,
    .CMSWrapper {
        &-Background {
            width: 100% !important;
        }
    }

    @include ultrascreen-desktop {
        .LandingWrapper,
        .LandingPageWrapper,
        .CMSWrapper {
            padding-inline: 1.5rem;
        }
    }

    @include widescreen-desktop {
        .LandingWrapper,
        .LandingPageWrapper,
        .CMSWrapper,
        .ContactPage {
            padding-inline: 2.5rem;
        }
    }

    @include medium-desktop {
        .LandingWrapper,
        .LandingPageWrapper,
        .CMSWrapper {
            padding-inline: 60px;
        }
    }

    @include tablet {
        .LandingWrapper,
        .LandingPageWrapper {
            padding-inline: 32px;
        }
    }

    @include mobile {
        .LandingWrapper,
        .LandingPageWrapper {
            padding-inline: 16px;
        }

        .ContactPage {
            margin-inline: -16px;
        }
    }

    @include mobile-tablet {
        .CMSWrapper {
            padding-inline: 2rem;
        }
    }
}

.ContactUs {
    display: flex;
    flex-direction: column;
    gap: 32px;

    h3 {
        font-weight: 600;
        font-size: 1rem;
        margin-block: 0 16px;
        line-height: 1.25rem;

        @include desktop {
            font-size: 1.125rem;
            line-height: 1.625rem;
        }
    }

    div, span, a {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
    }

    a {
        text-decoration: underline;
    }

    div, span {
        color: $black;
    }

    div {
        &:not(:last-child) {
            margin-block-end: 8px;
        }
    }

    &-Section {
        &:not(:last-child) {
            span {
                &:not(:last-child) {
                    &::after {
                        content: ': ';
                    }
                }
            }
        }

        &:last-child {
            div {
                &:last-child {
                    span {
                        display: block;

                        @include desktop {
                            display: inline;

                            &::after {
                                content: ' ';
                            }
                        }
                    }
                }
            }
        }
    }
}
