/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 * Original source: vendor/magefan/module-blog/view/frontend/web/css
 * Most Parts of these styles are copied from original extension Magefan Blog
 */

.PostList {
    display: flex;
    flex-direction: column;
    gap: 42px;
    margin-block-start: 0px;
    margin-block-end: 42px;

    @include desktop {
        margin-block-start: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 42px;
        column-gap: 32px;
    }

    @include tablet {
        margin-block-start: 0px;
        display: grid;
        column-gap: 24px;
        row-gap: 42px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &-LoadMoreButtonWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        gap: 16px;
        margin-block-start: 42px;

        .ProgressBar {
            &-Actions {
                width: 194px;
            }

            &-ArrowsWrapper {
                display: none;
            }
        }

        .Button {
            margin-block-start: 20px;
        }
    }

    &-HeaderSpacer {
        margin-block-start: 130px;

        @include desktop {
            margin-block-start: 170px;
        }
    }

    &-LoadPreviousButtonWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        gap: 16px;
        margin-block-start: calc(5px + var(--current-header-height));

        @include desktop {
            margin-block-start: 120px;
        }

        .ProgressBar {
            &-Actions {
                width: 194px;
            }

            &-ArrowsWrapper {
                display: none;
            }
        }
    }

    &-ProgressBarAndCount {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
    }

    &-DisplayedPostsCount {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.125rem;
        opacity: 0.5;
        margin: 0;
        text-align: center;
    }

    &-Wrapper {
        &_isLoading {
            min-height: 100vh;
        }
    }
}
