/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.UniqueSellingPoint {
    @media (hover: hover) {
        &-Item:hover {
            .UniqueSellingPoint {
                &-Heading {
                    cursor: pointer;

                    p {
                        color: $location-selected-label-color;
                    }
                }

                &-Line:last-of-type {
                    opacity: 1;
                    background-position: left bottom;
                }
            }

            .ChevronIcon {
                fill: $location-selected-label-color;
            }
        }
    }

    &-Heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px 0;

        p {
            text-transform: uppercase;
            font-size: $font-small-size;
            line-height: $lh-medium;
            font-weight: $fw-medium;
            letter-spacing: 0.08em;
            margin: 0;
            color: $default-primary-base-color;
            transition: color .3s linear;
            text-align: start;
        }

        .ChevronIcon {
            width: 24px;
            height: 24px;
            transition: fill .3s linear;
        }
    }

    &-Ingredients {
        display: none;

        p {
            color: $text-light-grey;
            margin-block-end: 12px;
        }

        &-Description {
            font-size: $font-medium-size;
            line-height: $lh-medium-large;
        }

        &-Disclaimer {
            font-size: $font-small-size;
            line-height: $lh-medium;
        }

        &_isOpen {
            display: block;
        }
    }

    &-Benefits {
        display: none;

        ul {
            list-style-type: none;
            margin-block-end: 12px;

            li {
                display: flex;
                align-items: center;
                font-size: $font-medium-size;
                line-height: $lh-medium-large;
                color: $text-light-grey;
                margin-block-end: 6px;

                &:last-of-type {
                    margin-block-end: 0;
                }

                &::before {
                    content: none;
                }

                .Image {
                    width: 32px;
                    height: 32px;
                    margin-inline-end: 16px;
                    padding: 0;
                }
            }
        }

        &_isOpen {
            display: block;
        }
    }

    &-Delivery {
        display: none;

        ul {
            list-style-type: none;
            margin-block-end: 12px;

            li {
                display: flex;
                flex-direction: column;
                margin-block-end: 12px;
                gap: 2px;

                &:last-of-type {
                    margin-block-end: 0;
                }

                &::before {
                    content: none;
                }

                .Delivery {
                    &-Main {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        span {
                            font-size: $font-medium-size;
                            line-height: $lh-medium-large;
                            color: $text-light-grey;
                        }
                    }

                    &-Info {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        span {
                            font-size: $font-small-size;
                            line-height: $lh-medium;
                            color: $text-light-grey;
                        }
                    }
                }
            }
        }

        &_isOpen {
            display: block;
        }
    }

    &-Gift {
        display: none;

        ol {
            margin-block-end: 12px;

            li {
                font-size: $font-medium-size;
                line-height: $lh-medium-large;
                font-weight: $fw-light;
                color: var(--link-color);
                text-decoration: underline;
                margin-block-end: 8px;

                &:hover {
                    cursor: default;
                }

                &:last-of-type {
                    margin-block-end: 0;
                }

                &::before {
                    display: inline-block;
                    position: static;
                    color: $text-light-grey;
                    font-size: $font-medium-size;
                    line-height: $lh-medium-large;
                    font-weight: $fw-light;
                    width: 24px;
                    margin-inline-end: 8px;
                    padding: 0;
                }

                a {
                    font-size: $font-medium-size;
                    line-height: $lh-medium-large;
                    font-weight: $fw-light;
                    text-decoration: underline;

                    &:hover {
                        color: $text-light-grey;
                        text-decoration: none;
                    }
                }
            }
        }

        &_isOpen {
            display: block;
        }
    }

    &-Line {
        width: 100%;
        height: 1px;
        opacity: 1;
        background: linear-gradient(to right, rgb(29, 30, 28) 50%, rgba(29, 30, 28, 0.16) 50%);
        background-size: 200% 100%;
        background-position: right bottom;
        transition: all .3s linear;
    }
}
