/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.CheckoutPayment {
    &-MCInnerContent {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-height: 25px;
        align-items: center;
        justify-content: space-between;

        span {
            flex-grow: 1;
        }

        img {
            max-width: 75px;
        }
    }

    &-Logo {
        border: 2px solid transparent;
        outline: 2px solid transparent;
        outline-offset: 1px;

        &_isSelected {
            outline-color: var(--primary-base-color);
        }
    }
}
