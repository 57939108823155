/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CurrencySwitcher {
    @include after-mobile {
        border-bottom: none;
    }

    .Field {
        @include desktop {
            height: 100%;
        }

        &-InputWrapper {
            @include desktop {
                height: 100%;
            }
        }

        &-Wrapper {
            @include desktop {
                height: 100%;
            }
        }

        &Select {
            width: auto;
            padding: 0;
            padding-inline-start: 0;

            @include desktop {
                padding-inline-start: 0;
                height: 100%;
            }

            &-Clickable {
                @include desktop {
                    height: 100%;
                }
            }

            &-Select {
                width: fit-content;
                min-width: 0;
                padding-inline-end: 10px;
                letter-spacing: 0.08em;

                @include after-mobile {
                    padding: 0;
                    border: none;
                    pointer-events: none;
                }
            }

            &-Options {
                min-width: 44px;
                border: none;
                inset-block-start: -8px;
                inset-inline-start: 4px;

                @include after-mobile {
                    overflow-y: auto;
                    width: min-content;
                }

                &Wrapper {
                    overflow-y: hidden;
                }

                @include desktop {
                    inset-block-start: -8px;
                }

                &_isExpanded {
                    @include after-mobile {
                        z-index: 15;
                        max-height: fit-content;
                    }
                }
            }

            &-Option {
                padding: 0;
                min-height: 0;
                font-size: 0.875rem;
                line-height: $lh-medium;
                letter-spacing: 0.05em;
                margin-block-end: 4px;

                @include ultra-wide-desktop {
                    line-height: unset;
                }

                &:last-of-type {
                    margin-block-end: 0;
                }
            }

            .ChevronIcon {
                position: static;
                min-height: 14px;
            }
        }
    }

    select {
        letter-spacing: 0.08em;
    }
}
