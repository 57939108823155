/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../../packages/@scandiweb/make-commerce/src/component/LocationFields/LocationFields.style';

:root {
    --select-height: 400px;
}

.LocationFields {
    position: relative;
    width: 100%;
    padding: 12px;
    padding-block-start: 0px;

    &-FieldsWrapper {
        position: relative;
    }

    &-SelectedLocation {
        position: relative;
        width: 100%;
        margin-block-start: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: var(--location-selected-label-bg-color);
        cursor: pointer;
    }

    &-SelectedLabel {
        color: var(--location-selected-label-color);
        opacity: 0.5;
        margin-block-start: 0px;
        height: 50px;
        padding-inline-start: 15px;
        padding-inline-end: 1rem;
        padding-block-end: 1rem;
        background-color: $location-selected-location-bg-color;
        margin-block-end: 0;
        font-size: 0.875rem;
        padding-block-start: 5px;

        &_selectedLocationMods {
            font-size: 1rem;
            padding-block-start: 15px;
        }
    }

    &-SelectedValue {
        position: absolute;
        margin-block-start: 25px;
        margin-inline-start: 15px;
        display: inline-block;
    }

    &-UpArrow,
    &-DownArrow {
        position: absolute;
        inset-block-start: 30%;
        inset-inline-end: 3%;
        border: solid var(--color-black);
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
    }

    &-UpArrow {
        inset-block-start: 45%;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    &-DownArrow {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    &-LocationInput {
        inset-block-start: -20px;
        width: 100%;

        #field-text-division, #field-text-area {
            width: 100%;
            padding: 0 15px;
            border: solid 1px var(--primary-base-color);
            min-height: 52px;

            &:focus {
                border-color: var(--primary-base-color);
            }
        }

        &:focus {
            border: 1px solid var(--primary-base-color);
        }
    }

    &-Options {
        background-color: var(--color-white);
        inset-block-start: -20px;
        min-width: 100%;
        height: auto;
        max-height: 400px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 7px;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--primary-base-color);
            border-radius: 5px;
        }
    }

    &-OptionsWrapper {
        max-height: 100%;
    }

    &-Option {
        display: flex;
        align-items: center;
        padding-inline-start: 14px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        color: var(--color-black);
        background-color: var(--color-white);
        padding-block: 8px 8px;

        &::after {
            content: '';
            position: absolute;
            inset-block-end: 0;
            inset-inline-start: 0;
            width: 0;
            height: 2px;
            background-color: var(--color-white);
            transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
        }

        &:hover {
            background-color: unset;

            &::after {
                @include desktop {
                    inset-inline: 0 auto;
                    width: 100%;
                }
            }
        }

        &:focus {
            &::after {
                inset-inline: 0 auto;
                width: 100%;
            }
        }
    }

    &-OptionAddress {
        opacity: 0.5;
        font-size: 14px;
        padding-inline-start: 14px;
    }
}
