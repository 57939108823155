/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */

.Breadcrumbs {
    display: block;
    padding-block-start: calc(35px + var(--current-header-height));
    background: var(--light-beige);

    @include mobile {
        padding-block-start: calc(5px + var(--current-header-height));
    }

    @include widescreen {
        padding-inline: 60px;
    }

    &-List {
        padding: 0;
        display: flex;
        align-items: center;

        .ChevronIcon {
            fill: var(--color-black);
            height: 16px;
            width: 16px;
        }
    }

    &-Back {
        display: flex;
        align-items: center;
        padding-block-end: 12px;
        margin-inline-end: 12px;
        cursor: pointer;
    }

    .ContentWrapper {
        display: inline-block;

        @include desktop {
            display: inherit;
        }

        @include widescreen {
            padding-inline: 0px !important;
        }

        @include tablet {
            padding-inline: 36px;
        }
    }

    &_pageName_pdp {
        .ContentWrapper {
            @include tablet {
                padding-inline: 52px;
            }
        }
    }
}
