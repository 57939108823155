.usp-delivery {
    list-style-type: none;
    margin-block-end: 12px;

    li {
        display: flex;
        flex-direction: column;
        margin-block-end: 12px;
        gap: 2px;

        &:last-of-type {
            margin-block-end: 0;
        }

        &::before {
            content: none;
        }
    }

    &-main {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: $font-medium-size;
            line-height: $lh-medium-large;
            color: $text-light-grey;
        }
    }

    &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: $font-small-size;
            line-height: $lh-medium;
            color: $text-light-grey;
        }
    }

    &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-size: $font-small-size;
        line-height: $lh-medium;
        font-weight: $fw-medium;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--button-background);
        border: 1px solid var(--button-background);
        margin-block-end: 12px;
        -webkit-transition: background .3s ease-in-out;
        transition: background .3s ease-in-out;

        &:hover,
        &:active {
            color: var(--color-white);
            background: var(--button-background);
        }
    }
}
